import { METHOD, frontmanZendeskApi } from '@/utils/api'

import { REQUESTS_STATUS, Request } from './types'

export type GetQnaListRequest = {
  page?: number;
  per_page?: number;
  status?: REQUESTS_STATUS;
  sort_by?: 'updated_at' | 'created_at';
  sort_order?: 'asc' | 'desc';
};

export type GetQnaListResponse = {
  count: number;
  next_page: string | null;
  previous_page: string | null;
  requests: Request[];
};

export const getQnaList = (query: GetQnaListRequest) => {
  return frontmanZendeskApi<GetQnaListResponse>({
    path: '/requests',
    method: METHOD.GET,
    query,
  })
}
